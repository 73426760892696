/*
	Developer Navdeep
	Email navdeep@blox.ae
*/

$(document).ready(function(){


	console.log("WATTZ");
});
